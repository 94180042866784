import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7b782136 = () => interopDefault(import('../sites/intimfitnesscz/pages/user/activate.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/user/activate" */))
const _08dce308 = () => interopDefault(import('../sites/intimfitnesscz/pages/search/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/search/index" */))
const _2d2ca6a2 = () => interopDefault(import('../sites/intimfitnesscz/pages/contact/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/contact/index" */))
const _07a5fe5a = () => interopDefault(import('../sites/intimfitnesscz/pages/checkout/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/checkout/index" */))
const _25add4b6 = () => interopDefault(import('../sites/intimfitnesscz/pages/articleList/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/articleList/index" */))
const _52731d98 = () => interopDefault(import('../sites/intimfitnesscz/pages/faq/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/faq/index" */))
const _74838a94 = () => interopDefault(import('../sites/intimfitnesscz/pages/shoppingGuide/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/shoppingGuide/index" */))
const _ad4d4030 = () => interopDefault(import('../sites/intimfitnesscz/pages/login.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/login" */))
const _15f0cff4 = () => interopDefault(import('../sites/intimfitnesscz/pages/register.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/register" */))
const _e95f60a6 = () => interopDefault(import('../sites/intimfitnesscz/pages/complaint/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/complaint/index" */))
const _3473f773 = () => interopDefault(import('../sites/intimfitnesscz/pages/passwordReset.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/passwordReset" */))
const _20e916b6 = () => interopDefault(import('../sites/intimfitnesscz/pages/sitemapconfig.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/sitemapconfig" */))
const _aaea555c = () => interopDefault(import('../sites/intimfitnesscz/pages/maintenance.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/maintenance" */))
const _181e9f1f = () => interopDefault(import('../sites/intimfitnesscz/pages/user/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/user/index" */))
const _59084106 = () => interopDefault(import('../sites/intimfitnesscz/pages/complaintReturn/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/complaintReturn/index" */))
const _32a92f70 = () => interopDefault(import('../sites/intimfitnesscz/pages/aboutPurchase/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/aboutPurchase/index" */))
const _b2b73b00 = () => interopDefault(import('../sites/intimfitnesscz/pages/term/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/term/index" */))
const _5bb20684 = () => interopDefault(import('../sites/intimfitnesscz/pages/passwordForgotten.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/passwordForgotten" */))
const _672df656 = () => interopDefault(import('../sites/intimfitnesscz/pages/checkout/info.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/checkout/info" */))
const _7e0f6fcc = () => interopDefault(import('../sites/intimfitnesscz/pages/checkout/finished.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/checkout/finished" */))
const _6ecb753a = () => interopDefault(import('../sites/intimfitnesscz/pages/checkout/methods.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/checkout/methods" */))
const _fb690318 = () => interopDefault(import('../sites/intimfitnesscz/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/checkout/importOrder/index" */))
const _016ff9dd = () => interopDefault(import('../sites/intimfitnesscz/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/user/shoppingLists/index" */))
const _e42d252a = () => interopDefault(import('../sites/intimfitnesscz/pages/user/orders/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/user/orders/index" */))
const _7ba5ceed = () => interopDefault(import('../sites/intimfitnesscz/pages/user/favourites.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/user/favourites" */))
const _3af01f34 = () => interopDefault(import('../sites/intimfitnesscz/pages/user/settings.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/user/settings" */))
const _6b6c4d61 = () => interopDefault(import('../sites/intimfitnesscz/pages/user/dashboard.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/user/dashboard" */))
const _370a1a44 = () => interopDefault(import('../sites/intimfitnesscz/pages/user/password.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/user/password" */))
const _3c7f2054 = () => interopDefault(import('../sites/intimfitnesscz/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/checkout/payment/callback" */))
const _409c865e = () => interopDefault(import('../sites/intimfitnesscz/pages/index.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/index" */))
const _3d7ccf85 = () => interopDefault(import('../sites/intimfitnesscz/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/user/shoppingLists/_id" */))
const _758c62da = () => interopDefault(import('../sites/intimfitnesscz/pages/user/orders/_id.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/user/orders/_id" */))
const _23f7336e = () => interopDefault(import('../sites/intimfitnesscz/pages/articleList/_slug.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/articleList/_slug" */))
const _52489871 = () => interopDefault(import('../sites/intimfitnesscz/pages/infomail/_slug.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/infomail/_slug" */))
const _0359caa0 = () => interopDefault(import('../sites/intimfitnesscz/pages/article/_slug.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/article/_slug" */))
const _71f1912e = () => interopDefault(import('../sites/intimfitnesscz/pages/product/_slug.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/product/_slug" */))
const _330e8ef6 = () => interopDefault(import('../sites/intimfitnesscz/pages/curatedGuidePage/_slug.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/curatedGuidePage/_slug" */))
const _67a2cf9b = () => interopDefault(import('../sites/intimfitnesscz/pages/page/_slug.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/page/_slug" */))
const _b6247d90 = () => interopDefault(import('../sites/intimfitnesscz/pages/term/_slug.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/term/_slug" */))
const _ce0d5d44 = () => interopDefault(import('../sites/intimfitnesscz/pages/_.vue' /* webpackChunkName: "sites/intimfitnesscz/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _7b782136,
    name: "user-activate___cs"
  }, {
    path: "/hledat",
    component: _08dce308,
    name: "search___cs"
  }, {
    path: "/kontakt",
    component: _2d2ca6a2,
    name: "contact___cs"
  }, {
    path: "/kosik",
    component: _07a5fe5a,
    name: "checkout___cs"
  }, {
    path: "/nakupni-radce",
    component: _25add4b6,
    name: "articleList___cs"
  }, {
    path: "/nejcastejsi-dotazy",
    component: _52731d98,
    name: "faq___cs"
  }, {
    path: "/poradna",
    component: _74838a94,
    name: "shoppingGuide___cs"
  }, {
    path: "/prihlasit",
    component: _ad4d4030,
    name: "login___cs"
  }, {
    path: "/registrovat",
    component: _15f0cff4,
    name: "register___cs"
  }, {
    path: "/reklamace",
    component: _e95f60a6,
    name: "complaint___cs"
  }, {
    path: "/resetovat-heslo",
    component: _3473f773,
    name: "passwordReset___cs"
  }, {
    path: "/sitemapconfig",
    component: _20e916b6,
    name: "sitemapconfig___cs"
  }, {
    path: "/udrzba",
    component: _aaea555c,
    name: "maintenance___cs"
  }, {
    path: "/uzivatel",
    component: _181e9f1f,
    name: "user___cs"
  }, {
    path: "/vraceni-zbozi",
    component: _59084106,
    name: "complaintReturn___cs"
  }, {
    path: "/vse-o-nakupu",
    component: _32a92f70,
    name: "aboutPurchase___cs"
  }, {
    path: "/vykladovy-slovnik-intimfitness",
    component: _b2b73b00,
    name: "term___cs"
  }, {
    path: "/zapomenute-heslo",
    component: _5bb20684,
    name: "passwordForgotten___cs"
  }, {
    path: "/kosik/dodaci-udaje",
    component: _672df656,
    name: "checkout-info___cs"
  }, {
    path: "/kosik/dokonceni",
    component: _7e0f6fcc,
    name: "checkout-finished___cs"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _6ecb753a,
    name: "checkout-methods___cs"
  }, {
    path: "/kosik/import-objednavky",
    component: _fb690318,
    name: "checkout-importOrder___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy",
    component: _016ff9dd,
    name: "user-shoppingLists___cs"
  }, {
    path: "/uzivatel/objednavky",
    component: _e42d252a,
    name: "user-orders___cs"
  }, {
    path: "/uzivatel/oblibene",
    component: _7ba5ceed,
    name: "user-favourites___cs"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _3af01f34,
    name: "user-settings___cs"
  }, {
    path: "/uzivatel/prehled",
    component: _6b6c4d61,
    name: "user-dashboard___cs"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _370a1a44,
    name: "user-password___cs"
  }, {
    path: "/checkout/payment/callback",
    component: _3c7f2054,
    name: "checkout-payment-callback___cs"
  }, {
    path: "/",
    component: _409c865e,
    name: "index___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy/:id",
    component: _3d7ccf85,
    name: "user-shoppingLists-id___cs"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _758c62da,
    name: "user-orders-id___cs"
  }, {
    path: "/clanky/:slug",
    component: _23f7336e,
    name: "articleList-slug___cs"
  }, {
    path: "/infomail/:slug?",
    component: _52489871,
    name: "infomail-slug___cs"
  }, {
    path: "/nakupni-radce/:slug",
    component: _0359caa0,
    name: "article-slug___cs"
  }, {
    path: "/produkt/:slug",
    component: _71f1912e,
    name: "product-slug___cs"
  }, {
    path: "/pruvodce/:slug",
    component: _330e8ef6,
    name: "curatedGuidePage-slug___cs"
  }, {
    path: "/vse-o-nakupu/:slug",
    component: _67a2cf9b,
    name: "page-slug___cs"
  }, {
    path: "/vykladovy-slovnik-intimfitness/:slug",
    component: _b6247d90,
    name: "term-slug___cs"
  }, {
    path: "/*",
    component: _ce0d5d44,
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
