import { merge } from 'lodash'
import contentSettings from '~/sites/virtshop/content.settings'

const overloadSettings = {
  cookieBot: {
    cbid: 'a2528c18-991c-489d-a4cd-870006cc093f',
  },
  events: {
    targito: {
      domain: 'targito.intimfitness.cz',
      origin: 'intimfitness_cz',
    },
  },
  functions: {
    currencyFromIp: {
      enable: true,
    },
  },
  seo: {
    alternateLinks: false,
  },
  SubCategoryBox: {
    parentExceptionSvg: '601ac40c316ad50017a7aad3',
  },
}

export default merge(contentSettings, overloadSettings)
