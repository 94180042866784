import { getters, mutations, actions as parentActions, state } from '~/sites/virtshop/store/globals'

const actions = {
  ...parentActions,
  SYNC_COUNTRY_WITH_CURRENCY({ commit, rootState }) {
    const currencyCode = rootState.currency.activeCurrency.code
    let countryCode = 'CZE'

    if (currencyCode === 'EUR') {
      countryCode = 'SVK'
    }

    commit(
      'cart/SET_CART_INPUT_FIELD',
      {
        field: 'country',
        value: countryCode,
      },
      { root: true },
    )
    commit(
      'cart/SET_CART_INPUT_FIELD',
      {
        field: 'billingCountry',
        value: countryCode,
      },
      { root: true },
    )
  },
}

export { actions, getters, mutations, state }
