<script>
export default {
  name: 'CurrencyMixin',
  computed: {
    countryCurrencyMap() {
      return new Map([['CZE', 'CZK'], ['SVK', 'EUR']])
    },
  },
}
</script>
