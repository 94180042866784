<script>
import AppRouteMixin from '~/sites/shop/mixins/AppRouteMixin'

export default {
  extends: AppRouteMixin,
  beforeMount() {
    this.$store.dispatch('globals/SYNC_COUNTRY_WITH_CURRENCY')
  },
}
</script>
