export * from '~/sites/virtshop/store/locale'

import { mutations, state as parentState } from '~/sites/virtshop/store/locale'

const state = () => ({
  ...parentState(),
  canonicalDomains: [{ language: 'cs', domain: 'https://www.intimfitness.cz' }],
})

export { mutations, state }
