import { getters, mutations, actions as parentActions, state } from '~/sites/virtshop/store/user'

const actions = {
  ...parentActions,
  async FETCH_USER({ commit, state, dispatch, rootState }, { forceReload = false }) {
    await parentActions.FETCH_USER.apply(this, arguments)

    // Set EUR currency for user with main address set to Slovakia
    if (state.userData.address?.countryAlpha2 === 'SK' || state.userData.address?.countryCode === 'SVK') {
      jsCookie.set('X-Inspishop-Currency', 'EUR')
    }
  },
}

export { actions, getters, mutations, state }
