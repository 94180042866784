import { merge } from 'lodash'
import themeSettings from '~/themes/virtshop/settings'

const overloadSettings = {
  components: {
    AddToCartModalItemAdded: {
      showAvailableGifts: false,
      displayedProducts: 'recommended',
    },
    BranchSelectorPPL: {
      countriesAvailable: 'cz,sk',
    },
    CartInfoDropDownContent: {
      showFreeGift: false,
    },
    CurrencySwitcher: {
      enable: true,
      icon: false,
    },
    EntryModal: {
      enable: false,
    },
    HeaderMain: {
      showCurrencySwitcher: true,
    },
    HeaderTop: {
      showCurrencySwitcher: false,
    },
    PageCheckoutItems: {
      showAvailableGifts: false,
    },
    PageCheckoutMethods: {
      enableInsurance: false,
      enableCountriesSwitcher: true,
    },
    PageContact: { showBranches: false },
    PageHomepage: {
      showHomepageArticles: false,
      showHomepageBranches: false,
      showHomepageIntimateAdvisory: false,
      showHomepageTextBlock: false,
      showNewestProducts: false,
      showTopCategories: true,
      topCategories: {
        enable: true,
        fullViewportWidthWrapper: false,
      },
    },
    PageCatalog: {
      badgeWidgetsInSidebar: {
        enableOn: ['desktop'],
      },
    },
    PageProductDetail: {
      badgeWidgetsInSidebar: {
        enableOn: ['desktop'],
      },
      parametersTable: {
        manufacturer: {
          code: false,
        },
        prices: {
          price: {
            enable: false,
          },
        },
        showProductParameters: false,
      },
      showAvailabilityInWarehouses: false,
      showAvailabilityInWarehousesInDeliveryOptions: false,
    },
    ProductsRecommended: {
      categoryProductsEnable: true,
      quarticonEnable: true,
    },
    PageTermsList: {
      showLetters: false,
    },
  },
  global: {
    showProductsRecommended: true,
  },
  layouts: {
    LayoutBase: {
      footerBadgeWidgets: {
        enableOn: ['mobile', 'tablet'],
      },
    },
  },
}

export default merge(themeSettings, overloadSettings)
