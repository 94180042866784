import { find } from 'lodash'
import { mutations, actions as parentActions, state } from '~/sites/virtshop/store/currency'

const actions = {
  ...parentActions,
  async CHANGE_CURRENCY_BY_CODE({ commit, dispatch, rootState }, { code }) {
    let currencies = rootState.currency.currencies

    if (currencies.length === 0) {
      await dispatch('FETCH_CURRENCIES')
      currencies = rootState.currency.currencies
    }

    const currencyObject = find(currencies, currency => currency.code === code)

    if (currencyObject && rootState.currency.activeCurrency.code !== code) {
      dispatch('CHANGE_CURRENCY', currencyObject)
    }
  },
}

const getters = {
  activeCurrency: state => {
    return state.activeCurrency
  },
}

export { actions, getters, mutations, state }
